
.control-group {

  height: 100vh;
  background: darkred;
  width: 1.4rem;

}
.control-group .menuitem {
  z-index: 10;
  display:block;
  margin: 0 auto 2rem auto;
  color: white;

}

.control-group .menuitem:hover{
  color: gray;
}

.control-space{
  width:100%;
}
.control-space p{
  z-index: 10;
  position: fixed;
  bottom: 0;
  le: 0;
  color: white;
  margin: 1rem 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.box{
  width: 6em;
  height: 6em;
  border: 2px dashed darkgrey;
}

:focus{
  outline: none;
}

.tools {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position:fixed;
  top:0;
  right:0;
  transition: right 1s;
  right: -40rem;
  margin-right: 1.4rem;
  padding: 0 0.4rem;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
}

.tools--visible {
  right:0;
}
