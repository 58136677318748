.waypointlist {
  border-collapse: collapse;
  width: 20em;
}

.waypointlist th, .waypointlist td {
  text-align: left;
}

.waypointlist tr:nth-child(even){background-color: #f2f2f2}
.waypointlist tr:nth-child(odd){background-color: #f7f7f7}


.waypointlist th {
  background-color: darkred;
  color: white;
}
