html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.App {
  height: 100vh;
  display: flex;
}

.RTZMap {
  height: 100%;
  flex-grow:2;
}

.MenuBar {
  z-index: 5;
  width: 1.4rem;
  height: 100%;
}
